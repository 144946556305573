import { createTheme, colors } from '@mui/material';

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: `#556cd6`,
        },
        secondary: {
            main: `#19857b`,
        },
        error: {
            main: colors.red.A400,
        },
        background: {
            default: `#fff`,
        },
    },
    typography: {
        h1: {
            fontWeight: `900`,
            fontSize: `calc(1.2em + 3vw)`,
            padding: `25px 0`
        },
        h2: {
            fontWeight: `800`,
            fontSize: `calc(1em + 2vw)`,
            paddingTop: `25px`,
            paddingBottom: `10px`
        },
        paragraph: {
            fontSize: `calc(16px + 1vw)`,
            lineHeight: `calc(1.1em + 0.5vw)`,
            padding:`0 50px`
		}
	}
});

export default theme;